<script setup>

</script>

<template>
    <div>
      hello
    </div>
</template>

<style scoped>

</style>